.orden-title {
	color: var(--color-orange);
    font-weight: 900;
    font-size: 1.7rem;
    line-height: 2.4rem;
    margin-bottom: 0.5rem;
}

.orden-subtitle {
	color: var(--color-btn);
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 2rem;
}

.item-text__title {
	font-weight: 800;
	font-size: 1.3rem;
	line-height: 1.6rem;
	color: var(--color-btn);
}

.item-text__title-fact {
	font-weight: 800;
	font-size: 1.2rem;
	line-height: 1.6rem;
	color: var(--color-btn);
	margin-bottom: .5rem;
}

.item-text__subtitle {
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.2rem;
	color: var(--color-btn);
}

.item-text__subtitle-fact {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.2rem;
	color: var(--color-btn);
	margin-bottom: .5rem;
	margin-top: 1rem;
}

.cursor {
	cursor: pointer;
}

.option-details__view {
	width: 180px;
	height: 16px;
	border: 0.5px solid var(--color-orange);
	border-radius: 3px;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.1rem;
	color: var(--color-orange);
	display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 9px 12px;
}

.option-details__link:hover {
	text-decoration: none;
}

.option-details__link:hover .option-details__view {
	background-color: var(--color-orange);
	color: var(--color-white);
}

.option-container {
    padding: 1rem 1rem 0;
    background: var(--color-gray-2);
}

.option-item {
	display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.option-details {
	display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.form-withdrawals__label {
	color: var(--color-orange);
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.2rem;
}

.title-modal {
	font-weight: 700;
	font-size: 1.7rem;
	line-height: 1.4rem;
	color: var(--color-white);
	text-align: center;
    width: 100%;
}

.header-modal {
	background: #FF6238;
	border-radius: 8px 8px 0px 0px;
	height: 50px;
	display: flex;
    align-items: center;
}

.btn-modal {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 3rem;
	margin-bottom: 2rem;
}

.btn-modal-confirm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
    width: 80%;
    margin: 4rem auto 2rem;
}


.title-completed {
	font-weight: 700;
	font-size: 3rem;
	line-height: 3.6rem;
	text-align: center;
	color: var(--color-orange);
	margin-top: 5rem;
    margin-bottom: 2rem;
}

.subtitle-completed {
	font-weight: 400;
	font-size: 1.4rem;
	line-height: 1.7rem;
	text-align: center;
	color: var(--color-btn);
	margin-bottom: 4rem;
}

.item-selected {
	width: 17px;
	height: 6px;
	background: var(--color-btn);
	border-radius: 10px;
	margin-right: 0.5rem;
}

.item-disabled {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: var(--color-btn);
	margin-right: 0.5rem;
}

.item-container {
	display: flex;
    align-items: center;
    justify-content: center;
}



.btn-options {
	display: block;
}

.opt-container {
	width: 100%;
	margin-bottom: 8rem;
	min-height: 300px;
}

.row-top {
	display: block;
	margin-top: 10rem;
}

.group-title {
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.4rem;
	color: var(--color-btn);
	margin-bottom: 0;
}

.group-title__calendar::before {
	content: url('../assets/images/calendar.png');
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: bottom;
    margin-right: 1rem;
}

.group-title__notification::before {
	content: url('../assets/images/notification.png');
	width: 18px;
	height: 20px;
	display: inline-block;
	vertical-align: bottom;
    margin-right: 1rem;
}

.group-view {
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.4rem;
	color: var(--color-gray-3);
	margin-bottom: 0;
	cursor: pointer;
}

.group-item__title {
	display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.group-item {
	display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    visibility: hidden;
}

.group-item__container {
	padding: 0;
}

.group-container {
	width: 100%;
}

.item-td {
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.4rem;
	color: var(--color-btn);
	vertical-align: middle!important;
}

.item-status {
	background: rgb(52 37 128);
    border-radius: 3px;
    text-align: center;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: 1rem;
}

.item-status__completed {
	width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--color-white);
    margin-right: 0.5rem;
}

.item-status__pending {
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background: var(--color-orange);
	margin-right: .5rem;
}

.item-status-pending {
	background: rgba(255, 98, 56, 0.1);
	border-radius: 3px;
	text-align: center;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.group-detail {
	width: 30%;
	display: none;
}

.detail-item__text {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.4rem 0;
}


.item__title {
	font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: var(--color-btn);    
}

.item__title-border {
	border-bottom: 0.5px solid var(--color-btn);
}

.input-search {
	height: 42px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--color-btn);
    width: 100%;
    padding-left: 3.5rem!important;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgb(52 37 128 / 15%);
    border-radius: 5px;
}

.option-details__dash {
	width: 76px;
}

.input-search__icon {
	content: url("../assets/images/arrow-orange.svg");
	width: 19px;
	height: 19px;
	display: inline-block;
	vertical-align: middle;
	position: absolute;
    top: 10px;
    left: 9px;
}

.detail-item__input .input-search {
	padding-left: 1rem;
}

.detail-item__input {
	position: relative;
}

.detail-item__search {
	width: 28px;
	height: 28px;
	background: var(--color-orange);
	border-radius: 5px;
	display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
}





.opt-container-table, .option-secondary {
	display: none;
}

.title-form {
	color: var(--color-btn);
	font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.selector {
	width: 100%;
	position: relative;
	margin-top: 1rem;
}

.select-field {
	width: 100%;
	padding: 12px 20px;
	margin-bottom: 0;
	box-sizing: border-box;
	background: #342580;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	color: white;
}

.select-field .arrowIcon {
	width: 12px;
	transition: transform 0.5s;
}

.select-text {
	margin-bottom: 0;
    font-size: 1.2rem;
}

.list-options {
	width: 100%;
	background: #342580;
	border-radius: 0 0 6px 6px;
	overflow: hidden;
	color: var(--color-white);
	border: 1px solid var(--color-gray);
	position: absolute;
    z-index: 100;
}

.options {
	width: 100%;
	padding: 10px 0 10px 54px;
	list-style: none;
	cursor: pointer;
	box-sizing: border-box;
	position: relative;
}

.options img {
	width: 15px;
	position: absolute;
	top: 12px;
	left: 25px;
}

.options p {
	font-size: 1.2rem;
	margin-bottom: 0;
	padding-top: .25rem;
}

.options:hover {
	background: rgb(118 112 151 / 90%);
}

.hide-options {
	visibility: hidden;
}

.rotate {
	transform: rotate(180deg);
}

.icon-selected {
	width: 15px;
	margin-right: 1rem;
}

.border-btracking {
	border-bottom: 1px solid var(--color-gray);
}

.border-ttracking {
	border-top: 1px solid var(--color-gray);
}


.container-package {
	display: flex;
    align-items: center;
}

.item-text__container {
	margin-left: 1rem;
}

.text-count {
	color: var(--color-orange);
    width: 38px;
    height: 38px;
    box-shadow: 0px 4px 12px rgb(52 37 128 / 10%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.group-title__office::before {
	content: url("../assets/images/office.svg");
	width: 19px;
	height: 19px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.5rem;
    margin-top: -0.5rem;
}

.col-padding {
	padding-left: 3rem!important;
}

.text-confirmation {
	font-size: 1.8rem;
    margin-top: 3rem;
    font-weight: 700;
    line-height: 3rem;
    text-align: center;
    color: var(--color-btn);
}

.confimartion-modal {
	padding-top: 4rem;
    padding-bottom: 4rem;
}

.form-group__btn {
	height: 5.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -0.5rem;
    margin-left: 1.5rem;
}

.btn-search__form::before {
	content: url("../assets/images/icon-search-orange.png");
	width: 19px;
	height: 19px;
	display: inline-block;
	margin-right: 1rem;
	vertical-align: middle;
	margin-top: -.5rem;
}

.breadcrumb {
	width: 48%;
}

.form-main__search  {
	width: 48%;
}

.d-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.d-form__breadcrumb {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.d-form__group {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.table-search {
	font-size: 1.2rem;
    color: var(--color-btn);
}

.table-search tbody, .form-search__label {
	font-size: 1.2rem;
    color: var(--color-btn);
}

.fixed-btn {
	background-color: white;
	display: flex;
	justify-content: space-between;
    align-items: center;
}

.height-table {
	min-height: 65vh;
}



.btn-atras {
	height: 38px;
    width: 80px;
    border-radius: 5px!important;
    background: var(--color-gray-2);
    font-size: 1.2rem!important;
    color: var(--color-btn)!important;
    border: 1px solid #d6d6d6!important;
}

.btn-atras:hover {
	background: #cbcbcb;
}

.btn-atras::before {
	content: url("../assets/images/icon-atras.png");
	width: 15px;
	height: 15px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.table-search>tbody>tr:nth-child(odd)>td, 
.table-search>tbody>tr:nth-child(odd)>th {
background-color: #FBFBFC;
}
.table-search>tbody>tr:nth-child(even)>td, 
.table-search>tbody>tr:nth-child(even)>th {
background-color: #FFF;
}

.options-not-records {
	display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 300px;
    justify-content: center;
	background: var(--color-gray-2);
}

.info-claim__title {
	font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.info-claim__subtitle {
	font-size: 1.2rem;
    margin-bottom: 0;
}

.pb-row-10 {
	margin-bottom: 10rem;
}

.title-det-orden {
	font-size: 1.3rem;
    font-weight: 900;
    color: var(--color-btn);
    text-align: center;
    margin-top: 2rem;
}

.det-orden-flex {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subtitle-flex {
	font-size: 1.2rem;
    color: var(--color-btn);
    font-weight: 400;
	text-align: right;
}

.subtitle-flex__bold {
    font-weight: 600;
}

.flex-container {
	border-radius: 5px;
    border: 1px solid var(--color-btn);
    padding: 2rem 1.5rem 1rem;
	width: 88%!important;
	margin-left: 2rem;
}

.flex-all {
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3rem;
    border-bottom: 1px solid var(--color-btn);
    margin-bottom: 2rem;
}

.c-pointer {
	cursor: pointer;
}

.search-icon-img {
	height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0;
    flex-direction: column;
}

.text-template {
	color: var(--color-btn);
    font-size: 1.6rem;
    font-weight: 400;
    text-align: center;
    margin-left: .5rem;
	margin-bottom: 3rem;
}

.text-template--link {
	color: var(--color-btn);
    font-size: 1.3rem;
    font-weight: 400;
    text-align: center;
    margin-left: .5rem;
}

.container-template {
	background: #F9F9FB;
    border: 0.5px dashed #342580;
    border-radius: 6px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
	width: 200px;
    margin: 0 auto;
    cursor: pointer;
}

.template-div-img {
	height: 27px;
}

.template-div-img img {
	height: 100%;
    object-fit: cover;
}

.text-template--link:hover {
	color: var(--color-btn);
}

.container-template--h {
	height: 135px;
}

.btn-options-modal {
	height: 50px!important;
    margin-bottom: 2rem;
}

.group-container-items {
	width: 100%;
}

.title-card {
	color: var(--color-btn);
    font-size: 1.2rem;
    font-weight: 600;
}

.subtitle-card {
	color: var(--color-btn);
    font-size: 1.2rem;
    font-weight: 400;
}

.item-card {
	width: 25%;
}

.item-card--main {
	width: 50%;
}

.card-line {
	border-bottom: 1px dashed #e7e7e7;
}

.card-line:last-child {
	border-bottom: none;
}

.card {
	display: flex;
    flex-direction: row;
    justify-content: flex-start;
	margin-bottom: 2rem;
}

.dropzone-container {
	height: 60px;
    background: #F9F9FB;
    border: 0.5px dashed #342580;
    background: #F9F9FB;
    border: 0.5px dashed #342580;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    margin: 0 auto;
}

.dropzone-text {
	font-weight: 400;
	font-size: 1.3rem;
	line-height: 19px;
	color: #342580;
	margin-bottom: 0;
}

.dropzone-icon {
	text-align: center;
    margin-bottom: 0;
    margin-right: 1rem;
    margin-top: .5rem;
}

.text-step {
	font-size: 1.4rem;
}

.container-ul {
	display: flex;
    align-items: flex-start;
    justify-content: center;
}

.li-item {
	width: 40%;
    font-size: 1.2rem;
	margin-bottom: 0;
}

.li-item li{
	list-style: initial;
}

.select-file {
	width: 70%!important;
}

.container-select-file {
	display: flex;
    align-items: center;
    justify-content: center;
	flex-direction: column;
}

.section-step {
	display: flex;
    align-items: flex-start;
	margin-bottom: 2rem;
	flex-direction: column;
}

.section-step-item {
	width: 100%;
	margin-bottom: 1rem;
}

.table-step {
	font-size: 1.25rem;
}

.table-font{
	font-size: 1.3rem;
}

.btn-options-modal {
	width: 100%;
    margin: 5rem auto 3rem;
}

.space-button {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2rem;
}

.text-pagination {
	font-size: 1.5rem;
	font-weight: bold;
	color: var(--color-btn);
}

.info-claim {
	display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 6px, rgba(0, 0, 0, 0) 0px 4px;
    padding: 10px;
	margin-bottom: 1.5rem;
}

.info-claim__header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.btn-send {
	width: 150px!important;
	height: 100%!important;
}

.btn-reply {
	height: 25px!important;
    font-size: 1.1rem!important;
	width: 100px!important;
}

.footer-modal-claim {
	display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.body-claim {
	min-height: 400px;
}

.claim-title-container {
	display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.item-text__title-claim {
	margin-bottom: 0;
}

.claim-badge {
	margin-left: 1rem!important;
    width: auto!important;
    height: 18px!important;
    font-size: 1.2rem!important;
}

@media only screen and (min-width: 768px) {
	.btn-options {
		display: none;
	}	

	.options-not-records__invoice {
		height: 200px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.group-item__container {
		padding: 0;
	}

	.paddinglr-0 {
		padding-left: 0!important;
		padding-right: 0!important;
	}

	.opt-container {
		margin-bottom: 3rem;
	}

	.row-top {
		display: none;
	}

	.group-item {
		padding: 0 2rem 1rem;
		visibility: visible;
	}

	.option-container {
		padding: 1.5rem 2rem 0;
	}

	.group-table {
		padding: 0 2rem;
	}

	.group-detail, .opt-container-table, .option-secondary {
		display: block;
	}

	.group-container {
		width: 70%;
		margin-top: 0;
	}

	.item-text__title-fact {
		font-size: 1.3rem;
	}

	.item-text__subtitle-fact {
		font-size: 1.2rem;
	}

	.item-td {
		font-size: 1.3rem;
	}

	.item-status {
		font-size: 1.2rem;
		padding: 0.5rem;
	}

	.option-details__view {
		font-size: 1.2rem;
	}

	.group-title {
		font-size: 1.4rem;
	}

	.input-search {
		box-shadow: none;
		border: 0.5px solid #342580;
	}

	.detail-item__text {
		padding: 0.75rem 0;
	}

	.item-text__subtitle {
		font-size: 1.2rem;
	}

	.item__title {
		font-size: 1.4rem;
	}

	.font-10 {
	    font-size: 1.4rem!important;
	}

	.input-search {
		padding-left: 1rem!important;
	}

	.border-ttracking {
		border-top: none;
		border-bottom: 1px solid var(--color-gray);
	}

	.search-icon-img {
		height: 500px;
		margin-left: 1rem;
	}

	.section-step {
		flex-direction: row;
	}

	.section-step-item {
		width: 45%;
	}

	.btn-options-modal {
		width: 50%;
	}
}

@media only screen and (min-width: 992px) {
	.option-details__dash {
		width: 140px;
	}

	.group-item {
		padding: 0 0 1rem;
	}

	.option-container {
		padding: 1.5rem 0 0;
	}

	.history-claim {
		padding: 0 7rem;
	}
}