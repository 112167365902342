.form-input {
    height: 52px!important;
}

.label-title {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--color-blue-text);
}

.form-account {
    width: 100%;
}

.list-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.btn-option {
    font-size: 1.5rem!important;
    background: #fff;
    border: #fff;
    margin-bottom: 1.5rem;
    padding: 1rem 2rem!important;
    width: 100%;
    text-align: left!important;
}

.btn-option--active {
    color: #fff!important;
    background: var(--color-blue-text)!important;
}

@media only screen and (min-width: 768px) {
    .list-button {
        flex-direction: column;
        margin-top: 0;
    }
}


@media only screen and (min-width: 992px) {
    .form-account {
        width: 75%;
    }
}