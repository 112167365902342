:root {
	--color-btn: #342580;
	--color-blue-text: #2F245B;
	--color-gray: #CED3D8;
	--color-gray-1: #E5E5E5;
	--color-blue: #0192DF;
	--color-white: #FFFFFF;
	--color-orange: #FF6238;
	--color-gray-2: #FBFBFC;
	--color-gray-3: #B3B3C6;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
	border: none;
	outline: none;
}

html {
	font-size: 62.5%;
}


body {
	background: var(--color-white);
	font-family: 'Poppins', sans-serif;
	font-size: 1.6rem;
}

textarea {
	resize: none;
}

.form-group {
	position: relative;
	width: 100%;
}

.container-main {
	margin-bottom: 10rem;
}

.btn-primary {
	background: var(--color-btn);
	border-radius: 5px;
	height: 48px;
	font-size: 1.5rem;
	font-weight: 400;
}

.btn-size-42 {
	height: 42px;
}

.btn-size-33 {
	height: 33px!important;
}

.font-10 {
	font-size: 1.2rem!important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: rgb(52 37 128 / 90%);
    border-color: var(--color-btn);
}

.btn-outline-primary {
	border-color: var(--color-btn);
	border-radius: 5px;
	height: 48px;
	font-size: 1.5rem;
	font-weight: 400;
	color: var(--color-blue-text);
}

.btn-secondary.focus, .btn-secondary:focus {
    color: #fff;
    background-color: var(--color-orange);
    border-color: var(--color-orange);
    -webkit-box-shadow: 0 0 0 0.2rem rgb(130 138 145 / 50%);
    box-shadow: 0 0 0 0.2rem rgb(255 98 56 / 50%);
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: var(--color-orange);
    border-color: var(--color-orange);
}

.btn-secondary:hover {
    color: #fff;
   	background-color: var(--color-orange);
    border-color: var(--color-orange);
}

.w-160 {
	width: 160px;
}

.btn-secondary {
	background: var(--color-orange);
	color: var(--color-white);
	border-color: var(--color-orange);
	border-radius: 5px;
	font-size: 1.5rem;
	font-weight: 400;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--color-blue-text);
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(47 36 91 / 25%);
    box-shadow: 0 0 0 0.2rem rgb(47 36 91 / 25%);
}

.modal-content {
	border-radius: 8px 8px 0px 0px!important;
}

.option-item__icon {
	width: 42px;
	height: 42px;
	background: #FFFFFF;
	box-shadow: 0px 4px 12px rgba(52, 37, 128, 0.1);
	border-radius: 5px;
	display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.option-item__icon:hover {
	background: rgb(255 98 56 / 50%);
}

.col-dsk {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.row-dsk {
	display: none;
}

.form-control {
	font-size: 1.25rem;
	height: 33px;
}

.text-danger {
	font-size: 1.2rem;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: rgb(52 37 128 / 90%);
    border-color: var(--color-btn);
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: rgb(52 37 128 / 90%);
    border-color: var(--color-btn);
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(52 37 128 / 50%);
}

.btn-other {
	font-size: 1.3rem;
    margin-top: 2.5rem;
}

.modal-close {
	cursor: pointer;
}

.accordion .card {
	border: none;
	border-bottom: 1px solid rgba(0,0,0,.125)!important;
	background: #FBFBFC;
}

.card-header {
	background: #FBFBFC;    
    padding: 1rem 1rem 2rem;
    border-bottom: none!important;
    cursor: pointer;
}

.card-header .accordion-text {
	background: var(--color-white)!important;
    color: var(--color-btn);
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0;
}

.accordion-header img {
	object-fit: contain;
}

.btn-image {
	margin-right: 0.7rem;
    display: inline-block;
}

.btn-new-retiro {
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem!important;
}

.input-item {
	position: relative;
	width: 100%;
	margin-top: 4rem;
	order: 2;
}

.btn-top-menu {
	order:0;
}

.options-icon {
	order: 1;
}

.input-main--icon {
	background: url("../assets/images/search-blue.png");
	width: 17px;
    height: 17px;
    display: inline-block;
    position: absolute;
    left: 9px;
    top: 13px;
}

.input-main {
	background: var(--color-gray-2);
	border-radius: 5px;
	height: 42px;
	width: 100%;
	padding-left: 4rem;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 1.4rem;
	color: var(--color-btn);
}

.input-main--delivery {
	background: url("../assets/images/group-search.png");
	width: 129px;
    height: 41px;
    display: inline-block;
    position: absolute;
    right: 9px;
    top: -4px;
}

.input-search::placeholder, .input-main::placeholder {
	color: var(--color-btn);
}

.spinner-grow {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
}

.text-spinner {
	color: var(--color-btn);
    font-size: 1.3rem;
}

.spinner-panel {
	height: 200px;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-package {
	font-size: 1.2rem!important;
}

.table-package tbody {
	font-size: 1.4rem!important;
}

.Toastify__toast-body {
	font-size: 1.4rem!important;
}

.text-cta-corriente {
	text-align: right;
    padding-right: 2rem;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    color: var(--color-btn);
}

.react-datepicker {
	font-size: 1.2rem!important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
	width: 2.5rem!important;
	line-height: 2.5rem!important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
	font-size: 1.2rem!important;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 1.4rem!important;
    color: var(--color-btn)!important;
    content: ">"!important;
}

.breadcrumb-item.active {
    color: var(--color-btn)!important;
}

.breadcrumb {
    background-color: var(--color-white)!important;
    padding: 1rem!important;
    margin-bottom: 0!important;
    margin-left: 3rem!important;
    font-size: 1.3rem;
}

.list-group-item {
	color: var(--color-blue-text);
    background-color: #fff;
    border-color: #fff;
    font-size: 1.5rem;
	font-weight: 400;
}

.list-group-item.active {
	color: var(--color-blue-text);
    background-color: #fff;
    border-color: #fff;
	font-weight: 600;
}

.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #fff;
    text-decoration: none;
    background-color: var(--color-blue-text);
}

.list-group {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 2rem;
    border-radius: .25rem;
    margin-top: 2rem;
}

.container-password {
	position: absolute!important;
    top: 14px;
    right: 12px;
	cursor: pointer;
}

::-ms-reveal {
    display: none;
}

.got-back {
	text-align: right;
    margin-right: 5rem;
    width: 100%;
    font-size: 1.5rem;
	color: var(--color-blue-text);
}

.got-back__link{
	color: var(--color-blue-text);
}

.got-back__link:hover {
	text-decoration: none;
}

.got-back__link::before {
	content: url("../assets/images/down-arrow-return.svg");
	display: inline-block;
    margin-right: .5rem;
}

.p-relative {
	position: relative;
}

.active-menu-desk {
	background: linear-gradient(270deg, rgba(255, 98, 56, 0.2) 0%, rgba(255, 98, 56, 0) 100%);
    border-right: 4px solid #FF6238;
	display: block;
}

.item-menu-dsk {
	display: block;
}

.list-unstyled {
	padding-left: 1rem!important;
}

.list-unstyled li {
	list-style-type: square;
}

.accordion .card-body {
	font-size: 1.2rem;
}

 .swiper-button-prev:after {
	content: url("../assets/images/arrow-left.svg");
}

.swiper-button-next:after {
	content: url("../assets/images/arrow-rigth.svg");
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
	left: -3px;
    right: auto;
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: -7px;
    left: auto;
}

.carousel-orden {
	width: 100%;
}

.dz-ui-label {
    font-size: 1.2rem!important;
    font-weight: 400!important;
	width: 295px!important;
}

.dropzone-ui .dz-ui-footer {
	display: none;
}

.file-item-full-container-container .file-item-icon-container .img-container img {
    height: 100%;
    object-fit: contain;
}

.file-item-full-container-container .file-item-icon-container {
    margin: 0 auto;
	height: 70px;
    width: 70px;
}

.file-item-list-container.file-item-list-grid {
	min-height: 200px!important;
    max-height: 200px!important;
}

.dz-ui-label::before {
	content: url("assets/images/icon-folder.svg");
	display: block;
}

.label-hidden {
	color: var(--color-white);
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

.text-loading-pdf {
	font-size: 1.4rem;
    color: var(--color-orange);
    margin-top: -4rem;
}

.container-pdf {
	position: absolute;
    right: 0;
    left: 0;
	height: "90vh";
    background: var(--color-white);
    padding-top: 15rem;
	text-align: center!important;
	margin-bottom: 3rem!important;
	padding-bottom: 3rem!important;
}

.text-count-destination {
	font-weight: bold;
    font-size: 12px;
}

.text-path {
	font-size: 12px;
}

.pagination {
    justify-content: flex-end;
    margin-top: 20px;
	font-size: 1.3rem;
  }

  .page-item.active .page-link {
    color: #fff!important;
    background-color: #342580!important;
    border-color: #342580!important;
}

.page-link {
    color: #7b7b7c!important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #342580!important;
    background-color: transparent;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #342580;
    border-color: #342580;
}

.modal-spinner .modal-content {
    background: transparent!important;
    border: none;
}

.modal-spinner .modal-content p{
	font-size: 1.5rem;
}

.form-group-select {
	height: 52px;
}

@media only screen and (min-width: 768px) {
	.row-dsk {
		display: block;
		margin-bottom: 5rem;
		margin-top: 4rem;
	}

	.list-group {
		flex-direction: column;
		margin-bottom: 0;
		margin-top: 0;
	}

	.card-header .accordion-text {
		font-size: 1.5rem;
	}

	.accordion .card-body {
		font-size: 1.4rem;
	}
}

@media only screen and (min-width: 992px) {
	.input-item {
		width: 50%;
	    order: 1;
	    margin-top: 0;
	}

	.options-icon {
		order: 2;
	}
}

.btn-secondary[disabled]:hover {
    color: #fff!important;
    background-color: #6c757d!important;
    border-color: #6c757d!important;
}


@media only screen and (min-width: 1200px) {
	.input-item {
		width: 60%;
	}

	.input-main--delivery {
		background: url("../assets/images/group-search-1.png");
		width: 187px;
	    height: 60px;
	    display: inline-block;
	    position: absolute;
	    right: 9px;
	    top: -19px;
	}

}