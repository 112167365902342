.container {
	background: var(--color-gray-2);
	border-radius: 8px;
	padding: 2rem 0;
	margin-bottom: 0;
}

.title {
	color: var(--color-btn);
	margin-bottom: 0;
	font-size: 1.2rem;
	font-weight: 800;
}

.address {
	color: var(--color-btn);
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 400;
    border-bottom: 1px solid #b2adcd;
    padding-bottom: 1rem;
}

.item-info__text {
	color: var(--color-btn);
	margin-bottom: 0;
	font-size: .85rem;
	font-weight: 400;
}

.item {
	display: flex;
	text-align: center;
	justify-content: flex-start;
	margin-top: 1rem;
}

.item-info {
	padding-left: 1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
	width: 100%;
}

.item-info a {
	color: var(--color-btn);
}

.icon-email::before {
	content: url("../assets/images/email.png");
	width: 16px;
	height: 13px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.icon-telephone::before {
	content: url("../assets/images/phone.png");
	width: 16px;
	height: 13px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.icon-map::before {
	content: url("../assets/images/map.png");
	width: 16px;
	height: 13px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}

.dsk-title {
	color: var(--color-btn);
	font-size: 1.8rem;
	font-weight: 400;
	margin-bottom: 0;
}

.dsk-subtitle {
	color: var(--color-btn);
	font-size: 2.5rem;
	font-weight: 800;
	margin-top: -0.5rem;
}

.col-p {
	padding-left: 3.5rem!important;
}

.info-address {
	color: var(--color-btn);
	font-size: 1.2rem;
	font-weight: 400;
	padding-top: 1rem;
}

.dsk-title-h3 {
	color: var(--color-btn);
	font-size: 1.2rem;
	font-weight: 700;
}

.dsk-title-h3::before {
	content: url("../assets/images/office.svg");
	width: 19px;
	height: 19px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.5rem;
    margin-top: -0.5rem;
}

.icon-time::before {
	content: url("../assets/images/time.svg");
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.5rem;
}

.dsk-img {
	margin-top: 5rem;
}

.select-comuna {
	display: none;
}

.description-comuna {
	display: none;
}

.dsk-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.select-office {
	font-size: 1.4rem!important;
    color: var(--color-btn)!important;
    padding: 0.9rem 1rem!important;
    height: 100%!important;
}

.item-info__link {
	cursor: pointer;
    text-decoration: underline;
}

@media only screen and (min-width: 380px) {
	.item-info__text {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 414px) {
	.item-info__text {
		font-size: 1.2rem;
	}
}

@media only screen and (min-width: 768px) {
	.container {
		padding-left: .5rem;
		margin-bottom: 2rem;
	}

	.title {
		font-size: 1.4rem;
	}

	.address {
		font-size: 1.4rem;
	}

	.item-info__text {
		font-size: 1.3rem;
	}

	.description-comuna {
		display: block;
	}

	.select-comuna {
		display: block;
	}
}