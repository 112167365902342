.side-menu {
    position: fixed;
    background: var(--color-btn);
    width: 300px;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    transition: width .2s ease-in;
    display: none;
}

.side-menu.inactive {
    width: 80px;
    padding: 20px;
}

.side-menu .top-section {
    position: relative;
    margin-bottom: 5rem;
}

.side-menu .top-section .logo {
    width: 200px;
    height: 70px;
    overflow: hidden;
    display: inline-block;
}

.side-menu.inactive .top-section .logo {
    width: 40px;
}

.side-menu .top-section .logo img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.side-menu .top-section .logo div {
    width: 200px;
    height: 70px;
}

.side-menu.inactive .top-section .logo div {
    width: 40px;
}

.side-menu .top-section .logo div img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.side-menu .top-section .toogle-menu-btn {
    color: #fff;
    font-size: 2rem;
    position: absolute;
    top: 10%;
    right: -30px;
    transform: translateY(-50%);
    cursor: pointer;
}

.side-menu.inactive .top-section .toogle-menu-btn  {
    right: -50px;
}

.side-menu .search-controller {
    color: #666;
    position: relative;
}

.side-menu .search-controller .search-btn {
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 2rem;
    color: #666;
}

.side-menu .search-controller input[type=text] {
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
}

.side-menu .main-menu .menu-item {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    font-weight: 600;
    cursor: pointer;
}

.side-menu .main-menu {
    margin: 5px 0;
}

.side-menu .main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    line-height: 40px;
    text-align: center;
}

.side-menu .main-menu .menu-item .menu-icon--home, .menu-bottom__item .menu-icon--home {
    content: url("../assets/images/icon-white/home.svg");
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 1.1rem;
    margin-left: -.5rem;
}



.side-menu .main-menu .menu-item .menu-icon--retiro, .menu-bottom__item .menu-icon--retiro {
    content: url("../assets/images/icon-white/retiro.svg");
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 1.1rem;
    margin-left: -.5rem;
}

.side-menu .main-menu .menu-item .menu-icon--office, .menu-bottom__item .menu-icon--office {
    content: url("../assets/images/icon-white/office.svg");
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 1.1rem;
    margin-left: -.5rem;
}

.side-menu .main-menu .menu-item .menu-icon--claim, .menu-bottom__item .menu-icon--claim  {
    content: url("../assets/images/icon-white/claim.svg");
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 1.1rem;
    margin-left: -.5rem;
}

.side-menu .main-menu .menu-item .menu-icon--question, .menu-bottom__item .menu-icon--question {
    content: url("../assets/images/icon-white/question.svg");
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-top: 1.1rem;
    margin-left: -.5rem;
}

.side-menu .main-menu .active-menu-desk .menu-item .menu-icon .menu-icon--home {
    content: url("../assets/images/icon-orange/home.svg");
}

.active-menu-mob .menu-bottom__item .menu-icon--home {
    content: url("../assets/images/icon-orange/home-mob.svg");
}

.side-menu .main-menu  .active-menu-desk .menu-item .menu-icon .menu-icon--retiro {
    content: url("../assets/images/icon-orange/retiro.svg");
}

.active-menu-mob .menu-bottom__item .menu-icon--retiro {
    content: url("../assets/images/icon-orange/retiro-mob.svg");
}

.side-menu .main-menu  .active-menu-desk .menu-item .menu-icon .menu-icon--office {
    content: url("../assets/images/icon-orange/office.svg");
}

.active-menu-mob .menu-bottom__item .menu-icon--office {
    content: url("../assets/images/icon-orange/office-mob.svg");
}

.side-menu .main-menu  .active-menu-desk .menu-item .menu-icon .menu-icon--claim {
    content: url("../assets/images/icon-orange/claim.svg");
}

.active-menu-mob .menu-bottom__item .menu-icon--claim {
    content: url("../assets/images/icon-orange/claim-mob.svg");
}

.side-menu .main-menu  .active-menu-desk .menu-item .menu-icon .menu-icon--question {
    content: url("../assets/images/icon-orange/question.svg");
}

.active-menu-mob .menu-bottom__item .menu-icon--question {
    content: url("../assets/images/icon-orange/question-mob.svg");
}

.side-menu .main-menu .menu-item span {
    position: absolute;
    display: inline-block;
    line-height: 4rem;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.menu-item {
    background: transparent;
    color: var(--color-white);
}

.side-menu .main-menu .sub-menu {
    color: #333;
    margin-left: 2rem;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 3rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in;
}

.side-menu .main-menu .sub-menu.active {
    max-height: 200px;
}

.side-menu .main-menu .sub-menu a {
    display: block;
    margin: 5px 0;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
}

.sider-menu-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 20px;
    box-sizing: border-box;
}


.sider-menu-footer .user-info {
    margin: 0 10px;
    color: #fff;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu .sider-menu-footer li a{
    display: inherit;
    font-size: 1.5rem;
    text-decoration: none;
    color: #fff;
}

.side-menu .sider-menu-footer li a span {
    margin-left: 1.5rem;
}

.side-menu.inactive .sider-menu-footer li a span {
    width: 0;
    height: 0;
    overflow: hidden;
    margin-left: 0;
}

.side-menu.inactive .sider-menu-footer li a.user-info {
    opacity: 1;
    width: 0;
    height: 0;
    overflow: hidden;
    font-size: 1.4rem;
    margin-bottom: 0;
}

.sider-menu-footer .user-info h5 {
    font-size: 1.5rem;
}


.divider {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #5A4BA6;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.container-home {
    margin-left: 0;
    transition: margin-left .2s ease-in;
}

.container-home.inactive {
    margin-left: 80px;
}

.avatar {
    position: relative;
}

.menu-footer {
    border: 0.5px solid #FFFFFF;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.menu-bottom {
    background: var(--color-btn);
    height: 60px;
    box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.25);
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    z-index: 100;
}

.menu-bottom a {
    width: 20%;
    height: 100%;
}

.menu-bottom__item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.menu-bottom__item:hover {
    background: rgb(106 90 187 / 50%);
}

.menu-bottom__item:hover .item-title {
    color: var(--color-orange);
}

.menu-bottom__item .item-title {
    font-family: 'Lato';
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0;
    color: var(--color-white);
    letter-spacing: .1rem;
}

.active-menu-mob .menu-bottom__item .item-title {
    color: var(--color-orange);
}

.menu-top {
    height: 62px;
    background: var(--color-btn);
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    padding-bottom: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

@media only screen and (min-width: 768px) {
    .side-menu {
        display: block;
    }

    .menu-top {
        display: none;
    }

    .menu-bottom {
        display: none;
    }

    .container-home {
        margin-left: 300px;
        width: 100%;
    }
}